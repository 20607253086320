@use '@angular/material' as mat;
@import '../../../../../node_modules/@angular/material/theming';

@mixin profile-list-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  .new-tag {
    background: mat.get-color-from-palette($primary, 300);
    color: mat.get-color-from-palette($primary, default-contrast);
  }
  .verified-tag {
    background: mat.get-color-from-palette($accent, 300);
    color: mat.get-color-from-palette($primary, 300);;
  }
  .price-tag {
    background: mat.get-color-from-palette($primary, 400);
    .currency-grid {
      background: mat.get-color-from-palette($primary, default-contrast);
      color: mat.get-color-from-palette($primary, 500);
    }
  }
}
