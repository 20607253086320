@import '@angular/material/theming';

@mixin sidenav-quick-info-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  gingr-sidenav-quick-info {
    background-color: mat.get-color-from-palette($primary, 600);
  }

  .quick-info__left {
    background-color: mat.get-color-from-palette($accent);
    color: mat.get-color-from-palette($primary);

    mat-icon {
      color: mat.get-color-from-palette($primary);
    }
  }
}
