@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin sidenav-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  .mat-divider {
    background-color: mat.get-color-from-palette($primary, 200);
  }

  .sidenav-top {
    background-color: #EE9AFF;
  }

  .avatar-name.mat-line {
    color: mat.get-color-from-palette($primary, 900)
  }
}

