@import 'stream-chat-angular/src/assets/styles/scss/index.scss';

@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #810278;
  --secondary-color: #FFD640;
  --message-input-background: #900886;
  --message-bubble-background: #a00b95;
  --primary-surface: #bb1ba7;
  --secondary-surface: #900886;
  --low-emphasis-text: rgba(255, 255, 255, 0.5);
}

.chat-header{
  @apply bg-[#810278] h-16 text-[#FFD640] shadow-md;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.str-chat {
  --str-chat__message-input-tools-color: var(--secondary-color);
  --str-chat__message-input-not-allowed-color:var(--low-emphasis-text);
  --str-chat__background-color: var(--primary-color);
  --str-chat__channel-list-background-color: var(--primary-color);
  --str-chat-icon-color: var(--secondary-color);
  --str-chat__message-textarea-background-color: var(--message-input-background);
  --str-chat__message-status-color: var(--secondary-color);
  --str-chat__message-secondary-color: white;
  --str-chat__date-separator-color: white;
  --str-chat__date-separator-line-color: var(--secondary-color);
  --str-chat__message-bubble-background-color: var(--message-bubble-background);
  --str-chat__message-bubble-border-radius: 6px;
  --str-chat__own-message-bubble-background-color: var(--message-bubble-background);
  --str-chat__message-link-color: var(--secondary-color);
  --str-chat__jump-to-latest-message-color: var(--primary-color);
  --str-chat__jump-to-latest-message-background-color: var(--secondary-color);
  --str-chat__secondary-background-color: var(--primary-color);
  --str-chat__tertiary-surface-color: var(--message-bubble-background);
  --str-chat__own-message-reaction-background-color: var(--message-bubble-background);
  --str-chat__avatar-background-color: var(--message-bubble-background);
  --str-chat__message-options-color: var(--secondary-color);
  --str-chat__message-actions-box-border-radius: 4px;
  --str-chat__message-actions-box-box-shadow: rgba(0, 0, 0, 0.2);
  --str-chat__message-actions-box-background-color: var(--message-bubble-background);
  --str-chat__message-reactions-options-background-color: var(--message-bubble-background);
  --str-chat__message-reactions-option-background-color: var(--message-bubble-background);
  --str-chat__primary-surface-color: var(--primary-surface);
  --str-chat__message-reactions-option-selected-background-color: var(--primary-surface);
  --str-chat__secondary-surface-color: var(--secondary-surface);
  --str-chat__text-low-emphasis-color: var(--low-emphasis-text);
  --str-chat__primary-color: var(--secondary-color);
  --str-chat__deleted-message-border-radius: 6px;
  --str-chat__typing-indicator-color: var(--secondary-color);
  --str-chat__typing-indicator-background-color: var(--message-input-background);
  --str-chat__message-error-message-color: var(--secondary-color);
  .str-chat__avatar .str-chat__avatar-image{
    object-fit:contain;
  }
  .str-chat__icon--action {
    @apply rotate-90;
    font-size:medium;
  }
   @apply rounded;
  .str-chat__channel-list-messenger__main {
    @apply relative;
  }

  .str-chat__message-textarea-container {
    @apply border-none;
  }

  .str-chat__message-inner .str-chat__message-options {
    @apply block;
  }

  .str-chat__channel-list-empty {
    --str-chat__channel-list-empty-indicator-color: var(--low-emphasis-text);
  }

  .stream-chat__paginated-list {
    @apply overflow-y-auto rounded-t;
  }
  .str-chat__channel-list {
    @apply border-none;
  }
  .str-chat__message-textarea-container {
    border-radius: 100px;
  }

  .str-chat__message-text,
  .str-chat-angular__message-bubble{
    border-radius: 4px !important;
  }

  .str-chat__empty-channel {
    --str-chat__channel-empty-color: var(--low-emphasis-text);
    --str-chat__channel-empty-indicator-color: var(--message-input-background);
  }

  .str-chat__channel-preview {
    --str-chat__channel-preview-background-color: var(--message-input-background);
    --str-chat__channel-preview-active-background-color: var(--primary-surface);
    --str-chat__channel-preview-hover-background-color: var(--primary-surface);
    --str-chat__channel-preview-message-time-color: white;
    --str-chat__channel-preview-message-status-color: var(--secondary-color);
    @apply border border-solid rounded-t;
    border-color: oklch(var(--sc));

    .str-chat__channel-preview-messenger--name {
      color: var(--secondary-color);
    }
  }



.dropdown-content{
  background-color: var(--message-bubble-background);
  li:hover {
    background-color: var(--primary-surface);
  }
}
  .emoji-mart-anchor {
    color: pink;
    @apply flex-none;
  }

  .emoji-mart-dark {
    .emoji-mart-scroll {
      @apply h-[280px];
    }

    .emoji-mart-search-icon {
      margin-top: 7px;
      color: var(--secondary-color);

      svg {
        color: var(--secondary-color);
        width: 18px;
        height: 18px;
      }}

      .emoji-mart-title-label {
        color: var(--secondary-color);
      }

      .emoji-mart-category .emoji-mart-emoji:hover:before {
        background-color: var(--message-bubble-background);
      }

      @apply bg-[#a00b95];
      .emoji-mart-search input {
        @apply bg-[#900886] border-none rounded-xl h-10 p-2;
      }

      .emoji-mart-category-label span {
        background-color: var(--message-bubble-background);
      }

      .emoji-mart-bar {
        border-color: oklch(var(--sc));
      }

      @apply border-none;
    }

    .str-chat__channel-header {
      --str-chat__channel-header-background-color: var(--primary-surface);
      --str-chat__channel-header-info-color: var(--secondary-color);
      @apply p-3 px-3 rounded-t;
    }

    .str-chat__message-input {
      @apply rounded-b;
      .str-chat__send-button {
        @apply bg-[#FFD640] mx-2;
      }

      .str-chat__icon {
        color: var(--secondary-color);
      }

      .str-chat__icon--send {
        color: var(--primary-color);
      }

      @apply border border-solid shadow-inner;
      border-color: oklch(var(--sc));
      --str-chat__message-input-background-color: var(--primary-color);
      @apply py-3;
    }

    .dropdown-content {
      @apply p-0;
    }

    .dropdown-content li {
      @apply border-b border-solid p-1;
      border-color: oklch(var(--sc));
    }

    .dropdown-content > li:last-of-type {
      @apply border-b-0;
    }
  }

  .str-chat__message-actions-list-item {
    @apply border-b border-solid px-2;
    border-color: oklch(var(--sc));

    &:hover {
      background-color: var(--primary-surface);
    }
  }

  .str-chat__reaction-selector {

  }

  stream-message-reactions-selector {
    @apply border-b border-solid m-0 p-1;
    border-color: oklch(var(--sc));
  }

  .chat-create-header {
    @apply bg-[#bb1ba7];
  }

  .custom-placeholder-color::placeholder {
    color: var(--low-emphasis-text);
  }

  #chat-root {
    @apply min-h-[84svh] max-h-[84svh] flex h-full font-['stream-chat-icons'];
    .btn-disabled {
      @apply cursor-default opacity-40;
    }

    .p-autocomplete {
      @apply w-full;
      .p-autocomplete-input {
        @apply w-full pt-1 pb-1 bg-[#a00b95] h-12 rounded-[1.5rem];
      }
    }

    .p-overlay {
      min-width: unset !important;
      @apply mt-2;
    }

    .p-autocomplete-item {
      @apply bg-[#a00b95] border border-solid px-4 py-2;
      border-color: oklch(var(--sc));

      &:hover, &:focus {
        background-color: var(--primary-surface);
      }
    }

    .menu-open {
      @apply w-full h-full z-10;
    }

    .menu-close {
      @apply w-0;
    }

    .menu-button {
      @apply block;
    }

    .channel {
      @apply min-w-0 w-full;
    }

    .thread {
      @apply w-full h-full fixed z-10;
    }

    .channel {
      &.menu-open {
        @apply hidden;
      }

      .channel-list.thread-open {
        &.menu-open {
          @apply w-full h-full fixed z-10 md:w-[30%];
        }

        &.menu-close {
          @apply w-0;
        }

        & + .channel .menu-button {
          @apply block;
        }
      }

      .channel-list.thread-close {
        @apply w-full md:w-[30%] relative z-auto;

        & + .channel .menu-button {
          @apply hidden;
        }
      }

      .thread {
        @apply w-full relative z-auto md:w-[55%];
      }
    }

    @media screen and (min-width: 768px) {
      .menu-button {
        @apply hidden;
      }

      .channel-list {
        @apply w-full relative z-auto md:w-[45%] border-0;
        max-width:25rem;
      }

    }
    @media screen and (max-width: 768px) {
      .growMax {
        min-height: unset !important;
      }

      stream-message-list {
        @apply mt-16;
      }
      stream-message-input {
        @apply fixed bottom-0 w-full;
      }
      stream-channel-header {
        @apply fixed top-16 z-10 w-full;
      }
      .channel-list {
        @apply max-h-[92vh] min-h-[92vh] max-w-[100vw];

      }

    }
  }

  @media only screen and (max-device-width: 768px) {
    .str-chat__message-options {
      --str-chat__message-options-button-size: 40px;

      svg {
        @apply scale-150;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .growMax {
      min-height: unset !important;
    }
  }
