.sidebar-toggle {
  transform: translate3d(0, 0, 0);
  transition: all .2s;
  visibility: visible !important;
}

.mat-sidenav-closed .sidebar-toggle {
  transform: translate3d(13em, 0, 0);
}

.sidenav {
  width: 100%;
  position: absolute;
  overflow: hidden;
  background: $background__general-nav;
}

.sidenav.sidenav--left {
  max-width: 90px;
  width: 90px;
  text-align: center;
  background: $background__general-nav;
  transition: max-width .2s ease-in-out;
  &.sidenav--account {
    max-width: 400px;
    width: 400px;
    background-color: $background__account-nav;
    .sidenav-account {
      border-bottom: none;
      margin-bottom: 90px;
    }
  }
  &.sidenav--mobile {
    position: fixed;
    max-width: 100vw;
    width: 100vw;
  }
}

// for opened sidebar
.mat-drawer-opened .sidenav.sidenav--left {
  & ~ .mat-drawer-content {
    margin-left: 90px;
  }

  &.sidenav--account ~ .mat-drawer-content {
    margin-left: 300px;
  }
}

.sidenav-bottom {
  background: $background__general-nav;
  width: 100%;
}

.sidenav--right {
  max-width: 300px;
}

:host .mat-sidenav-content,
:host .sidenav {
  display: flex;
  overflow: visible;
}

:host .mat-sidenav-content {
  flex-direction: column;
  overflow-y: scroll;
}

/*
mat-sidenav#sideNav--alt::before {
  left: auto;
  right: -100px;
  transform: skew(20deg);
  background-color: darken(mat.get-color-from-palette($primary, 900), 10);
}
*/
