@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin last-seen-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  .ls-trigger {
    background-color: mat.get-color-from-palette($accent);

    .mat-icon {
      background-color: mat.get-color-from-palette($primary);
      color: mat.get-color-from-palette($accent);
    }

    span {
      color: mat.get-color-from-palette($primary);
    }
  }


  .ls-content {
    background-color: mat.get-color-from-palette($primary);
  }
}
