@use '@angular/material' as mat;

// Import Angular Material theming
@import '@angular/material/theming';

// Only ever import this once - HERE
@include mat.core();

// Color palettes for themes
@import 'themes/palette.pink';
@import 'themes/palette.yellow';

// Themes
/*
@import 'default-theme';
@import 'light-theme';
@import 'dark-theme';
*/

$primary: mat.define-palette($gingr-pink);
$accent:  mat.define-palette($gingr-yellow, 500, A200, A100);
$warn:    mat.define-palette(mat.$red-palette);
$theme:   mat.define-dark-theme((
        color: (
                primary: $primary,
                accent: $accent,
                warn: $warn
        ),
        typography: mat.define-typography-config(),
        density: 0
));


// Themes - Custom Components
@import '../app/app.component.scss-theme';
@import '../app/shared/components/filters/filters.component.scss-theme';
@import '../app/shared/components/header/header.component-theme';
@import '../app/shared/components/last-seen/last-seen.component.scss-theme';
@import '../app/shared/components/location-selector/location-selector.component.scss-theme';
@import '../app/shared/components/ribbon/ribbon.component.scss-theme';
@import '../app/shared/components/sidenav-navigation/sidenav-navigation.component.scss-theme';
@import '../app/shared/components/waves/waves.component.scss-theme';
@import '../app/account/containers/dashboard/dashboard.component.scss-theme';
@import '../app/account/account.component.scss-theme';
@import '../app/account/components/sidenav-quick-info/sidenav-quick-info.component.scss-theme';
@import '../app/shared/components/profile-list/profile-list.component.scss-theme';

@mixin custom-components-theme($theme) {
  @include app-component-theme($theme);
  // Shared components
  @include filters-component-theme($theme);
  @include header-component-theme($theme);
  @include last-seen-component-theme($theme);
  @include location-selector-component-theme($theme);
  @include ribbon-component-theme($theme);
  @include sidenav-component-theme($theme);
  @include waves-component-theme($theme);
  // Account components
  @include account-component-theme($theme);
  // Dashboard components
  @include dashboard-component-theme($theme);
  @include sidenav-quick-info-component-theme($theme);
  // Profile components
  @include profile-list-component-theme($theme);
}

// Theme classes
@include custom-components-theme($theme);
@include mat.all-component-themes($theme);

/*
.default-theme {
  @include custom-components-theme($theme);
  @include angular-material-theme($theme);
}

.light-theme {
  @include angular-material-theme($light-theme);
  @include custom-components-theme($light-theme);
}

.dark-theme {
  @include angular-material-theme($dark-theme);
  @include custom-components-theme($dark-theme);
}
*/
$color-primary: map-get($theme, primary);
$color-accent: map-get($theme, accent);
$color-warn: map-get($theme, warn);

/*
  Colors
*/
$color__primary: mat.get-color-from-palette($color-primary, 500);
$color__accent: mat.get-color-from-palette($color-accent);
$color__warn: mat.get-color-from-palette($color-warn);

$color__purple-dark: #6d036a;
$color__purple-light: #762670;
$color__pink: #e785fb;
$color__yellow: #ffd640;
$color__orange: #f99932;
$color__white: #ffffff;
$color__green: #7ad512;
$color__red: #ff0015;
$color__purple: #a91892;
$color__aqua: #72D1D7;
$color__aqua-dark: #13A5A2;

$color__accent-gradient: linear-gradient(to right, $color__accent, #ffe45e, $color__accent); // used for buttons

/*
Background colors
*/
$background__card-lighter: #880885;
$background__card-light: #772073;
$background__card-dark: #5D094A;
$background__card-darker: #2B0E29;


$background__general: #78006F; // for all public facing pages
$background__account: #5D094A; // for all account pages

$background__general-nav: #5D094A;
$background__account-nav: #78006F;

$background__dark: #2e2d2d;
$background__darker: #212121;

$background__tile: #b753a6;
$background__ls-trigger-bg: #b21a9d;

/*
  Layout
*/
$max-width-xs: 599px;
$max-width-sm: 959px;
$max-width-md: 1279px;
$max-width-lg: 1919px;
$max-width-xl: 5000px;

$min-width-xs: 600px;
$min-width-sm: 960px;
$min-width-md: 1280px;
$min-width-lg: 1920px;

/*
  Dimensions
*/
$content__width: 1170px;
$account__width: 960px;
$account__width_md: 850px;

$mobile__horizontal__spacing: 24px;
$mobile__vertical__spacing: 24px;

/*
  Font sizes
*/
$text: 1.1875rem; // general text
$text-sm: 1rem;
$text-xs: 0.875rem;

$heading: 24px;
$subheading: 21px;
$button_text: 21px; // button titles
$tabs_text: 24px; // tab titles
$list_headings: 19px; // list headings
$list_headings_alt: 14px; // list headings with value above

/*
  Lists
*/
$col-list__value-width: 60%;
