/* THIS WILL BE DEPRACATED */
.dream-button {
  font-size: 21px;
  letter-spacing: 2px;
  font-weight: 400;
  min-height: 60px;
  border-radius: 0;
  text-transform: uppercase;
}

.dream-button--primary {

  &:not(:disabled) {
    border: 1px solid $color__accent;
    background-color: transparent !important;

    span {
      color: $color__accent;
    }

    &:hover {
      background-color: $color__accent !important;

      span {
        color: $color__primary;
      }
    }
  }
}

.dream-button--secondary {

  &:not(:disabled) {
    border: none;
    background-color: $color__accent-gradient;

    span {
      color: $color__primary;
    }
    /* HOVER EFFECT TO BE CONFIRMED
    &:hover {
      background-color: $color__accent !important;

      span {
        color: $color__primary;
      }
    }
    */
  }
}

.mat-mdc-raised-button {
  text-transform: uppercase;
}

a {
  color: $color__accent;
  text-decoration: none;
}
