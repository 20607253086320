
@import 'var';
@import 'mixins';
@import 'type';
@import 'animations';
@import 'buttons';
@import 'icons';
@import 'layout';
@import 'sidenav';
@import 'maps';
@import 'misc';
@import 'forms';
@import 'dialogs';
@import "@ctrl/ngx-emoji-mart/picker";
@import 'stream-chat';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  .btn {
    min-height: 3.25rem;
    height: auto;
    font-size: 1rem;

    &-lg {
      min-height: 4rem;
    }

    &-sm {
      min-height: 2rem;
    }

    &-xs {
      min-height: 1.5rem;
    }

    &.btn-primary {
      &[disabled] {
        background-color: #FFD640;
        color: #810278;
        opacity: 0.5;
      }
      &:hover {
        background-color: #FDDE6D;
      }
    }
  }

  p-dropdown {
    &.error {
      .p-dropdown {
        border-bottom-color: rgb(255 27 0);
      }
    }
  }

  .btn-rounded-big {
    border-radius: 26px;
  }

  .btn-active-transparent {
    --fallback-inc: #ffd640;
    border-color: transparent;
  }

  .checkbox {
    --chkbg: #FFD640;
    --chkfg: #A91892;
    border: 2px solid #FFD640;
    border-radius: 5px;
  }

  ngx-mat-intl-tel-input {
    &.error {
      .ngx-mat-tel-input-container {
        &:after {
          background: rgb(255 27 0);
        }
      }
    }
  }

  --swiper-pagination-fraction-color: #FFD640;
}

.mat-drawer-container {
  overflow-y: scroll !important;
}

.swiper-button-disabled {
  display: none !important;
}

html, body {
  width: 100%;
  height: 100%;
  background-color: $background__ls-trigger-bg;
  overscroll-behavior: none;
  @media screen and (max-width: 768px) {
    overflow-y: auto;
  }
}

.gOverlay {
  background: #990f81;
  border: 2px solid #f0c53b;
  color: white;
  padding: 15px;
  border-radius: 2px;
}

.custom-placeholder {
  position: absolute;
  color: $color__pink;
  font-weight: bold;
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  padding-top: 0.5em;

  &.phone-placeholder {
    left: 25px;
  }
}

.intl-tel-input {

  .iti__selected-flag {
    padding: unset !important;
  }
  .iti__arrow {
    border-top-color: white;
  }

  input {
    height: 40px;
    background: transparent;
    outline: none;
    color: white;
    border: none;
    padding-left: 15px !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    margin-left: 32px !important;
    width: calc(100% - 32px) !important;
  }

  .dropdown-menu {
    &.country-dropdown {
      background: #424242;
      border: none;
      border-radius: 0;
    }
  }

  .iti__country-list {
    background: #424242;
    color: white;
  }

  &.tel-100 {
    > div {
      width: 100%;

      input {
        width: 100%
      }
    }
  }
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

@media screen and (max-height: 700px){
  .cdk-overlay-pane {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.register-dialog--panel,
.login-dialog--panel,
.instant-booking{
  background-color: oklch(var(--pc));
  @media screen and (max-width: 768px) {
    .mat-mdc-dialog-container .mdc-dialog__surface {
      box-shadow: none
    }
  }
}




.mat-mdc-menu-content {
  .country-list-button {
    color: white !important;
    padding: 5px 14px !important;
    font-size: 15px !important;
  }
  .icon-wrapper {
    padding-right: 10px !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px oklch(var(--pc)) inset !important;
}

input:-webkit-autofill{
  -webkit-text-fill-color: #fff !important;
  font-family: "Asap", sans-serif !important;
  font-size: 16px;
}

.openMobileMenu .bottom-navigation,
.openCategMenu .bottom-navigation{
  z-index: -1;
}

.mat-mdc-tab-header {
  background-color: #5d094a;
}
.mat-mdc-tab-list {
  height: 100%;
  .mat-mdc-tab-labels {
    height: 100%;
    .mat-mdc-tab {
      height: 100%;
      min-height: 57px;
      .mdc-tab__text-label {
        font-size: 16px;
        color: #fff;
        line-height: 18px;
        font-weight: 500;
      }
      &.mdc-tab-indicator--active {
        .mdc-tab__text-label {
          color: #FFD640;
        }
        .mdc-tab-indicator__content {
          border-color: #FFD640;
        }
      }
    }
  }
}


.p-float-label {
  outline: 0;
  .p-component {
    width: 100%
  }
  .p-dropdown {
    background-color: transparent !important;
    border-bottom: 1px solid white;
    color: white;
    border-radius: 0;
    outline: 0 none !important;
    .p-inputtext {
      padding: 10px 0 3px;
      color: white;
    }
  }
  .p-dropdown-trigger {
    padding-right: 0;
    width: auto;
    svg path {
      fill : white;
      stroke: white;
      stroke-width: 1px;
      stroke-linejoin: round;
    }
    svg {
      max-width: 10px;
      margin-top: 4px;
      margin-bottom: -4px;
    }
  }
  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none
  }

}
.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label{
  background-color:transparent;
  left: 0;
  color: #FFD640;
  font-size: 14px;
  margin-top: -5px;
}

.p-float-label label {
  color: #fff;
  font-size: 16px;
  left: 0;
  margin-top: -6px;
}

.screenResize {
  @media screen and (min-width: 769px) and (max-height: 900px) {
    height: calc(100svh - 288px);
  }
  @media screen and (max-width: 768px) {
    height: 100%;
  }
  @media screen and (min-width: 769px) and (min-height: 950px){
    max-height: calc(100svh - 288px) !important;
    height: 100% !important;
    min-height: 623px !important;
  }
}

.screenResizeInsta {
  @media screen and (min-width: 769px) and (max-height: 900px) {
    height: calc(100svh - 160px);
    min-height: auto !important;
  }
  @media screen and (max-width: 768px) {
    height: 100%;
  }
  @media screen and (min-width: 769px) and (min-height: 950px){
    max-height: calc(100svh - 160px) !important;
    height: 100% !important;
    min-height: 623px !important;
  }
}
.screenResizeBooking {
  @media screen and (min-width: 769px) and (max-height: 900px) {
    height: calc(100svh - 176px);
  }
  @media screen and (min-width: 769px) and (min-height: 901px) {
    height: calc(100svh - 176px);
    max-height: 812px;
  }
  @media screen and (max-width: 768px) {
    height: 100%;
  }
}

.screenResizeTransactions {
  @media screen and (min-width: 769px) and (max-height: 900px) {
    height: calc(100svh - 386px);
  }
  @media screen and (min-width: 769px) and (min-height: 901px) {
    height: calc(100svh - 386px);
    max-height: 500px;
  }
  @media screen and (max-width: 768px) {
    height: 100%;
  }
}

.btn-outline:hover {
  background: none !important;
  border:2px solid #FDDE6D;
  color: #FDDE6D;
  span {
    color: #FDDE6D;
  }
}
.text-balance {
  text-wrap: balance !important;
}
