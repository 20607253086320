/*
  Smooth svg paths
*/
svg path {
  shape-rendering: auto;
}

/*
  Material: mat-divider
*/
mat-divider.mat-divider {
  border-top-width: 2px;
  margin: 16px 0;
}

/*
  Star (heart) ratings
*/
.star-container .star svg,
.rating.color-positive .star-container .star svg,
.rating.color-ok .star-container .star svg {
  fill: $color__pink !important;
}

.rating.color-negative .star-container .star svg {
  fill: $color__pink !important;
}

/*
  Lightbox Gallery
*/
.cdk-overlay-container image-item div {
  background-size: auto;
  background-repeat: no-repeat;
}

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; /* Fix 401 */
}

.noUi-connect {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.noUi-origin {
  position: absolute;
  height: 0;
  width: 0;
}

.noUi-handle {
  position: relative;
  z-index: 1;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: top 0.25s, right 0.25s, bottom 0.25s, left 0.25s;
  transition: top 0.25s, right 0.25s, bottom 0.25s, left 0.25s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-handle-touch-area{
    position: relative;
    width: 44px;
    height: 44px;
    left: -15px;
    top: -15px;
}
/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  left: -17px;
  top: -6px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}

/* Styling;
 */
.noUi-target {
  background: #cdcdcd;
  border-radius: 4px;
  border: 1px solid transparent;
}

.noUi-connect {
  background: $color__accent;
  -webkit-transition: background 450ms;
  transition: background 450ms;
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF,
  inset 0 1px 7px #EBEBEB,
  0 3px 6px -3px #BBB;
}

.noUi-active {
  box-shadow: inset 0 0 1px #FFF,
  inset 0 1px 7px #DDD,
  0 3px 6px -3px #BBB;
}

/* Handle stripes
 */
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px;
}

.noUi-handle:after {
  left: 17px;
}

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}

.noUi-vertical .noUi-handle:after {
  top: 17px;
}

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #999;
}

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}

.noUi-marker-sub {
  background: #AAA;
}

.noUi-marker-large {
  background: #AAA;
}

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  -webkit-transform: translate3d(-50%,50%,0);
  transform: translate3d(-50%,50%,0);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  -webkit-transform: translate3d(0,50%,0);
  transform: translate3d(0,50%,0);
  padding-left: 25px;
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid transparent;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

/* Materialize Styles */
.noUi-target {
  border: 0;
  border-radius: 0;
}
.noUi-horizontal {
  height: 3px;
}

.noUi-vertical {
  height: 100%;
  width: 3px;
}

.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: none;
  background-color: $color__accent;
  border: none;
  left: -5px;
  top: -6px;
  transition: width .2s cubic-bezier(0.215, 0.610, 0.355, 1.000),
  height .2s cubic-bezier(0.215, 0.610, 0.355, 1.000),
  left .2s cubic-bezier(0.215, 0.610, 0.355, 1.000),
  top .2s cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

.noUi-handle:before {
  content: none;
}

.noUi-handle:after {
  content: none;
}

.noUi-target .noUi-active.noUi-handle {
  width: 3px;
  height: 3px;
  left: 0;
  top: 0;
}

.noUi-target.noUi-horizontal .noUi-tooltip {
  position: absolute;
  height: 30px;
  width: 30px;
  top: -17px;
  left: -2px;
  background-color: $color__accent;
  border-radius: 50%;
  transition: border-radius .25s cubic-bezier(0.215, 0.610, 0.355, 1.000),
  transform .25s cubic-bezier(0.215, 0.610, 0.355, 1.000);
  transform: scale(.5) rotate(-45deg);
  transform-origin: 50% 100%;
}

.noUi-target.noUi-horizontal .noUi-active .noUi-tooltip {
  border-radius: 15px 15px 15px 0;
  transform: rotate(-45deg) translate(23px, -25px);
}

.noUi-tooltip span {
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 12px;
  opacity: 0;
  position: absolute;
  top: 6px;
  left: -1px;
  transition: opacity .25s cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

.noUi-horizontal .noUi-tooltip span {
  transform: rotate(45deg);
}

.noUi-vertical .noUi-tooltip span {
  transform: rotate(135deg);
}

.noUi-target.noUi-vertical .noUi-tooltip {
  position: absolute;
  height: 30px;
  width: 30px;
  top: -17px;
  left: -2px;
  background-color: $color__accent;
  border-radius: 50%;
  transition: border-radius .25s cubic-bezier(0.215, 0.610, 0.355, 1.000),
  transform .25s cubic-bezier(0.215, 0.610, 0.355, 1.000);
  transform: scale(.5) rotate(-45deg);
  transform-origin: 50% 100%;
}

.noUi-target.noUi-vertical .noUi-active .noUi-tooltip {
  border-radius: 15px 15px 15px 0;
  transform: rotate(-135deg) translate(35px, -10px);
}

.noUi-vertical .noUi-tooltip span {
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 12px;
  transform: rotate(135deg);
  opacity: 0;
  position: absolute;
  top: 7px;
  left: -1px;
  transition: opacity .25s cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

.noUi-horizontal .noUi-active .noUi-tooltip span,
.noUi-vertical .noUi-active .noUi-tooltip span {
  opacity: 1;
}

/*
  Webkit: Custom scrollbar
*/

@media screen and (min-width: 600px) {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    padding-right: 10px;
  }
  ::-webkit-scrollbar-button {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #b21a9d;
    border: 58px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #d4a523;
  }
  ::-webkit-scrollbar-thumb:active {
    background: $color__accent;
  }
  ::-webkit-scrollbar-track {
    background: #5a0548;
    border: 0px none #ffffff;
    border-radius: 46px;
  }
  ::-webkit-scrollbar-track:hover {
    //background: #ffc72a;
  }
  ::-webkit-scrollbar-track:active {
    background: $color__primary;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}

/*
  Hide play btn
 */
*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

/*
  Functional Styles
*/
.box-shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

.no-padding {
  padding: 0;
}

.no-padding-right {
  padding-right: 0;
}
