@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin app-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  a {
    color: mat.get-color-from-palette($accent);
  }

  .title--primary,
  .color--primary {
    color: mat.get-color-from-palette($primary);
  }

  .title--accent {
    color: mat.get-color-from-palette($accent, 800);
  }

  .app-title,
  .app-title--inner {
    border-bottom-color: mat.get-color-from-palette($primary);
    color: mat.get-color-from-palette($accent);
  }

  .app-title--sub {
    color: lighten(mat.get-color-from-palette($primary, 500), 5);
  }

  .mat-title--yellow,
  .color--yellow {
    color: mat.get-color-from-palette($accent);
  }

  .color--pink_light {
    color: rgb(243, 160, 255);
  }

  // Angular Material: List
  .mat-mdc-list .mat-mdc-list-item .mat-line:nth-child(n+2),
  .mat-nav-list .mat-list-item .mat-line:nth-child(n+2),
  .mat-selection-list .mat-list-item .mat-line:nth-child(n+2) {
    color: mat.get-color-from-palette($primary);
  }

  // Angular Material: Expansion Panel
  .mat-expansion-panel {
    background: darken(mat.get-color-from-palette($primary, 900), 5);

    .mat-expansion-panel {
      background: darken(mat.get-color-from-palette($primary, 900), 10);
    }

    .mat-expansion-panel-header.mat-expanded .mat-expansion-panel-header-title {
      color: mat.get-color-from-palette($accent);
    }
  }

  // Angular Material: Card
  // Angular Material: Dialog
  .mat-mdc-card {
    background-color: darken(mat.get-color-from-palette($primary, 900), 10);
  }

  mat-sidenav-container {
    /*
    mat-toolbar {
      button {
        &.active {
          color: mat.get-color-from-palette($accent, default-contrast);
          background-color: mat.get-color-from-palette($accent);
        }
      }

      .sign-in-button {
        color: mat.get-color-from-palette($accent);
        border: 1px solid mat.get-color-from-palette($accent);
      }

      .link {
        color: mat.get-color-from-palette($primary, default-contrast);
      }
    }
    */
    .sidenav {
      // background-color: darken(mat.get-color-from-palette($primary, 900), 10);

      a {
        &.active {
          color: mat.get-color-from-palette($accent, default-contrast);
          background-color: mat.get-color-from-palette($accent);
        }
      }
    }

    .sidenav--right {
      // background-color: darken(mat.get-color-from-palette($primary, 900), 10);
    }

    .footer {
      color: mat.get-color-from-palette($primary, lighter);
      background-color: mat.get-color-from-palette($primary, darker);

      .links,
      .signature {
        a {
          color: mat.get-color-from-palette($primary, lighter);
          &:hover {
            color: mat.get-color-from-palette($accent);
          }
        }
      }
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
  .mat-card-super {
    background-color: mat.get-color-from-palette($primary);

    h3 {
      color: mat.get-color-from-palette($accent);
    }
  }

  .slides-wrapper__icon {
    color: mat.get-color-from-palette($accent);
  }
}
