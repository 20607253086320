.cdk-overlay-container .mat-mdc-dialog-container {
  background-color: $background__card-dark;
  position: relative;

}

.cdk-overlay-container .mat-mdc-dialog-surface {
  background-color: transparent !important;
  overflow-y: unset !important;
  padding: 24px;
  border-radius: 4px;
}

.cdk-overlay-container .cdk-overlay-dark-backdrop {
  background-color: rgba(0,0,0,.5);
}

.cdk-overlay-container .ShowVideoComponent-popup {
  margin-top: 64px;
}

// .cdk-overlay-pane {
//   max-width: 100% !important;
//   width: 100%;
//   height: 100%;
// }

.noPaddingModal,
.booking-process--outlet,
.no-scroll-modal,
.booking-client-preview,
.gingr-only-for-client--dialog,
.full-screen-modal{
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-surface{
    padding: 0 !important;
  }
}

.booking-client-preview {
  @media screen and (max-width: 768px) {
    .mdc-dialog__surface{
      box-shadow: none !important;
    }
  }
}

.booking-process--decline,
.booking-process--extra{
  margin: 0 auto !important;
  .mat-mdc-dialog-container {
    padding: 0 !important;
  }
}

@media (min-width: 1280px) {
  .customInstaModal {
    height: auto !important;
  }
}

.customInstaModal,
.chat-box-dialog{
  background-color: #78006f !important;
  .mat-mdc-dialog-container,
  .mat-mdc-dialog-surface{
    background-color: transparent;
    padding: 0;
  }
  @media screen and (min-width: 768px){
    max-width: 480px !important;
    margin: auto !important;
  }
}


  .customInstaModal2{
    .mat-mdc-dialog-container,
    .mat-mdc-dialog-surface{
      background-color: transparent;
      padding: 0;
    }
    @media screen and (min-width: 768px){
      max-width: 480px !important;
    }
    @media screen and (max-width: 768px) {
      max-width: 100% !important;
    }
  }



.customReviewModal {
  .mat-mdc-dialog-container {
    padding: 0 !important;
  }
  @media screen and (min-width: 768px){
    max-width: 498px !important;
    margin-left: calc(50vw - 249px) !important;
  }
}


.booking-client-preview {
  max-height: 80vh !important;
  overflow: visible !important;

  .mat-mdc-dialog-container {
    overflow-x: hidden;
    background: #762670 !important;
    @media screen and (max-width: 768px) {
      padding: 0 !important;
    }
  }
  @media screen and (max-width: 768px){
    max-height: 100svh !important;
    width: 100vw !important;
    max-width: 100vw !important;
  }
}


.cdk-overlay-pane .material-icons.close {
  color: $background__card-dark;
  border-radius: 50%;
  background-color: $color__accent;
  cursor: pointer;
  font-size: 24px;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(18px, -18px);
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 599px){
    transform: translate(0, 0);
    display: block;
    position: absolute;
    top: 12px;
    right: 12px;
    background: transparent;
    color: $color__accent;
    width: 24px;
    height: 24px;
  }
}

.cdk-overlay-pane.gingr-dialog .mat-mdc-dialog-container {
  position: relative;
}

.cdk-overlay-pane.gingr-dialog,
.cdk-overlay-pane.gingr-dialog .mat-mdc-dialog-container {
  overflow: visible
}

.cdk-overlay-pane .gingr-dialog__content {
  margin: 0;
  padding: 0;
  max-height: calc(700px - 198px);
  height: 100%;
}

.cdk-overlay-pane .gingr-dialog__content.gingr-dialog__content--hookup-details {
  max-height: calc(700px - 178px);
}

.cdk-overlay-pane .gingr-dialog__content.gingr-dialog__content--hookup-location {
  max-height: calc(700px - 64px);
}

.cdk-overlay-pane .gingr-dialog__title {
  margin-bottom: 0;
}

.cdk-overlay-pane .gingr-dialog__actions {
  padding: 0;
}

/* Select Content background color */
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.cdk-overlay-pane .mat-select-content,
.cdk-overlay-pane .mat-select-panel-done-animating {
  background-color: $color__purple-dark;
}

.gingr-dialog__row {
  // min-height: 150px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;
  position: inherit;
  padding: 0 16px;

  &.gingr-dialog__row--height-150 {
    min-height: 150px;
  }
}

.gingr-dialog__pill-wrapper {
  position: relative;
  z-index: 2;
  flex-wrap: wrap;
  background-color: $background__general;
  width: 100%;
  padding: 5px 0 11px;
  transition-delay: .1s, 0s, 0s;
  transition: height .2s linear, padding-bottom .1s linear, opacity .1s linear;

  &.hidden {
    height: 0;
    padding-bottom: 0;
    opacity: 0;
  }

  .gingr-dialog__pill {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    width: calc(50% - 8px);
    margin: 8px 8px 4px 8px;
    min-height: 30px;
    border-radius: 2px;
    background-color: #900886;
    text-align: center;
    padding: 6px 0;

    &:nth-child(odd) {
      margin-left: 0;
    }

    &:nth-child(even) {
      margin-right: 0;
    }

    &.active {
      background-color: $color__yellow;
      color: $background__general;
    }
  }
}

.gingr-hook-up-outlet--dialog .hookup-icon {
  color: $color__yellow;
  width: 36px;
  height: 36px;
  font-size: 36px;
}

.gingr-hook-up-outlet--dialog .gold {
  padding: 0 8px;
  color: $color__yellow;
}

@media(max-width: 599px) {
  .cdk-overlay-pane.gingr-dialog {
    max-width: 100vw !important;
    width: 100vw;
    height: 100%;
  }

  .cdk-overlay-pane .gingr-dialog__content {
    max-width: 100vw;
    margin: 0;
    padding: 0;
    max-height: calc(100svh - 198px);
    height: 100%;
  }

  .cdk-overlay-pane .gingr-dialog__content.gingr-dialog__content--hookup-details {
    max-height: calc(100svh - 172px);
  }

  .cdk-overlay-pane .gingr-dialog__content.gingr-dialog__content--hookup-location {
    max-height: calc(100svh - 60px);
  }

  .gingr-hook-up-outlet--dialog .hookup-icon {
    width: 30px;
    height: 30px;
    font-size: 30px;
  }
}


// .cdk-overlay-container .mobile{
//   min-width: 280px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 100%;
// }

@media screen and (max-width: 768px) {
  .preview-tabs__gallery {
    display: none;
  }
}
