@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin waves-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  // WAVES -- STORM
  .wave {
    background-color: darken(mat.get-color-from-palette($primary, 900), 10);
  }

  // WAVES -- CALM
  .waves__calm {
    path {
      fill: darken(mat.get-color-from-palette($primary, 900), 10);
    }
  }

}
