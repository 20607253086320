@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin ribbon-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  gingr-ribbon .ribbon {
    border-bottom-color: mat.get-color-from-palette($accent);

    &::before,
    &::after {
      border-color: transparent transparent transparent mat.get-color-from-palette($accent);
    }
  }

  .ribbon__content {
    color: mat.get-color-from-palette($primary);
  }
}
