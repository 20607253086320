/*
  Global font override
*/
*:not(.mat-icon):not(.fa):not(.p-rating-icon):not(.str-chat__icon) {
  font-family: 'Asap', sans-serif !important;
}

/*
  Font smoothing
*/
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

html,
body {
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

/*
  Headings
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color__accent;
  text-transform: uppercase;
}

/*
  Titles
*/
.app-title {
  width: 100%;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  padding-bottom: 24px;
  margin-bottom: 32px !important;

  .mat-icon {
    position: relative;
    transform: translateY(5px);
    margin-right: 8px;
  }
}

.app-title--uppercase {
  text-transform: uppercase;
}

.app-title--inner {
  font-size: 19px;

  & * > {
    font-size: 19px;
  }
}

@mixin title {
  color: $color__accent;
  margin-bottom: 32px;
  // margin-bottom: 1.684em;
  text-transform: uppercase;
}

// Massive titles
.gg-jumbo-title-2 {
  font-size: 60px;
  // font-size: 3.158em;
  @include title;
}

// Big titles
.gg-jumbo-title {
  font-size: 45px;
  // font-size: 2.368em;
  @include title;
  margin-bottom: 20px;
}

// Section titles
.gg-heading {
  // font-size: 1.263em;
  font-size: 24px;
  @include title;
  font-weight: 700;
  letter-spacing: 1.1px;
  position: relative;

  @media screen and (max-width: 599px) {
    margin: 0;
    padding: $mobile__vertical__spacing $mobile__vertical__spacing;
  }

  &.gg-heading--icon {
    margin-bottom: 2em;

    .heading-icon {
      position: absolute;
      left: -35px;
      @media screen and (max-width: 599px){
        position: initial;
        margin-right: 5px;
      }
    }
  }

  & + .gg-below-heading {
    font-size: 16px;
    color: white;
  }

  &.gg-heading--no-top {
    margin-top: 0;
  }
}

// Section subtitles
.gg-subheading {
  font-size: 21px;
  // font-size: 1.105em;
  color: $color__pink;
  text-transform: uppercase;
  font-weight: 600;

  @at-root #{&}--thin {
    font-weight: 400;
  }
}

// List headings
.gg-list-heading {
  // font-size: 1em;
  font-size: 19px;
  color: $color__pink;
  text-transform: uppercase;
  font-weight: 600;
}

/*
  General
*/
.to-uppercase {
  text-transform: uppercase;
}

* {
  /*letter-spacing: .5px;*/
}

p {
  // font-size: 1em;
  font-size: 16px;
  /*letter-spacing: .5px;*/
  line-height: 22px;
}

/*
  Functional Styles
*/
.fs21 {
  font-size:  1.313rem; // 21px
}

