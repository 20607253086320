$gingr-yellow: (
  50 : #fffae8,
  100 : #fff3c6,
  200 : #ffeba0,
  300 : #ffe279,
  400 : #ffdc5d,
  500 : #ffd640,
  600 : #ffd13a,
  700 : #ffcc32,
  800 : #ffc62a,
  900 : #ffbc1c,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffefcd,
  A700 : #ffe7b3,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/* OLD
$gingr-yellow: (
  50 : #fffae8,
  100 : #fff3c6,
  200 : #ffeba0,
  300 : #ffe379,
  400 : #ffdd5d,
  500 : #ffd740,
  600 : #ffd33a,
  700 : #ffcd32,
  800 : #ffc72a,
  900 : #ffbe1c,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffefcd,
  A700 : #ffe8b3,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
*/
