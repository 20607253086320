@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin account-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  .review__header-name {
    color: mat.get-color-from-palette($accent);
  }

  gingr-subscription {
    .account-page-title {
      border-bottom-color: mat.get-color-from-palette($primary);
      color: mat.get-color-from-palette($accent);
    }
  }

  // gingr-sidenav-account .sidenav-account__link {
  //   &:focus {
  //     background-color: mat.get-color-from-palette($accent) !important;
  //   }
//
  //   /* &.active::after {
  //     border-color: transparent transparent transparent mat.get-color-from-palette($accent);
  //   } */
  // }

}
