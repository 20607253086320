@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin header-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  .tooltip-wrapper {
    .tooltip {
      background-color: darken(mat.get-color-from-palette($primary, 900), 10);
      &:after {
        border-bottom: solid darken(mat.get-color-from-palette($primary, 900), 10) 10px;;
      }
    }
  }
}
