/*
  Spacers
*/
.spacer {
  display: block;
  margin-top: 3em;
}

.vertical-separator {
  margin: 0 12px;
  display: block;
  width: 1.5px;
  height: 15px;
  background: white;
}

/*
  Page containers
*/
.app-content-container {
  min-height: 100%;
  // background-color: $background__general;
  @media screen and (max-width: 599px) {
    // space to mobile bottom navigation
    margin-bottom: 50px;
  }

  //@media screen and (min-width: 768px) {
  //  & > [class*="ng-enter"],
  //  & > [class*="ng-leave"],
  //  & > [class*="ng-animating"] {
  //    max-width: calc(100% - 90px);
  //  }
  //}
}

.app-content-container--account {
  background-color: $background__account;
  min-height: 100%;
  @media screen and (max-width:768px) {
    min-height: calc(100svh - 100px);
    .languages-list-container {
      padding-bottom: 15px !important;
    }
  }
}


gingr-account .gg-page {
  min-height: calc(100vh - 64px) !important;
  background-color:  oklch(var(--sc));
  @media(max-width: 768px) {
    min-height: auto;
    background-color:  oklch(var(--pc));
  }
}

.page-container {
  max-width: $content__width;
  margin: 0 auto;
  width: 100%;
  padding: 32px;
  min-height: calc(100svh - 64px);
  box-sizing: border-box;

  @media(max-width: 599px) {
    min-height: auto;
  }
}

.page-container--location-height {
  min-height: initial;
}

.page-container--account {
  max-width: $account__width;
  padding: 32px 0;

  @media (min-width: 1280px) and (max-width: 1400px) {
    max-width: $account__width_md;
  }

  @media screen and (max-width: 599px) {
    padding: 0;
  }
}

.content-container {
  width: 100%;
  max-width: $content__width;
  margin: 0 auto;
}

@media(max-width: 1440px) {
  .content-container {
    width: 90%;
  }
}

// Section wrapper: currently used on Overview
.section-wrapper {
  margin-bottom: 3em;
}

.block-overlay {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 102%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(120, 0, 111, .4);
  }
}


/*
  Profile tiles
*/
.profile-tiles {
  max-width: 100%;
  width: 100%;
  margin: 10px 0 0;

  @media screen and (max-width: 599px){
    margin: 0 12px;
  }

  .profile-tile {
    width: 100%;

    .progress-bar-container{
      background: transparent;
      margin: 0;
      padding: 0;
      bottom: 0;
      height: auto;
      /*
  TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.
*/
      mat-card-content{
        border-radius: 0;
      }
    }
  }
}

/*
  Functional Styles
*/
.mb16 {
  margin-bottom: 1rem; // 16px
}

.mx16 {
  margin-left: 1rem; // 16px
  margin-right: 1rem;
}

.mx8 {
  margin-left: 0.5rem; // 8px
  margin-right: 0.5rem;
}


// cards

.mat-mdc-card-outlined  {
  border: 0 !important;
  .mat-mdc-card-content {
    padding: 0 !important
  }
}
