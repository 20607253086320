.mat-icon {
  &.icon-s48 {
    width: 48px;
    height: 48px;
  }

  &.icon-s32 {
    width: 32px;
    height: 32px;
  }

  &.icon-s30 {
    width: 30px;
    height: 30px;
    font-size: 30px;
  }

  &.icon-s29 {
    width: 29px;
    height: 29px;
    font-size: 29px;
  }

  &.icon-s24 {
    width: 24px;
    height: 24px;
  }
}
