@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin filters-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  .filters-form {

    .mat-accordion {
      background-color: mat.get-color-from-palette($primary);
    }

    .filters-form__bottom {
      background-color: mat.get-color-from-palette($primary);

      &::before {
        border-right-color: mat.get-color-from-palette($primary);
      }
    }
  }

  .filters-form__top {
    background-color: transparent;
  }

  gingr-filters .mat-accordion .mat-expansion-panel {
    background: transparent;
    box-shadow: none;
  }

  .filters-apply {
    background-color: mat.get-color-from-palette($primary);
  }
}
