
/*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
.mat-focused .mat-form-field-label {
  color: $color__pink;
}

/*
.form-row {
  gingr-form-field:not(:first-child) {
    margin-left: 2em;
  }
}
*/

.mat-mdc-form-field {
  margin-bottom: 1em;
}

/*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
.mat-form-field-label-wrapper {
  overflow: visible;
}

/*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
.mat-mdc-form-field .mat-form-field-infix {
  width: 100% !important;
}

/*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
/*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
.mat-form-field-can-float {
  /*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
  .mat-form-field-label {
    padding: 1px 0;
    color: white;
  }
  /*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
  &.mat-form-field-should-float .mat-form-field-label-wrapper .mat-form-field-label {
    color: $color__pink;
    // transform: translateY(-1.7em) scale(0.943) perspective(100px) translateZ(0.001px);
    font-weight: bold;
  }
}

.mat-mdc-form-field-infix {
  padding: 0 !important;
  min-height: auto !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}


// floating Inputs

.mat-mdc-form-field-infix {
  padding: 0.4375em 0;
  border-top: 0.84375em solid transparent;
  display: flex;
}
.mat-mdc-floating-label {
  top: 9px !important;
}
.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 1;
  background-color: transparent !important;
}
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label{
  color: #fff !important;
}
.mdc-text-field--filled .mdc-floating-label--float-above.mat-mdc-floating-label{
  color: oklch(var(--p)) !important;
  font-weight: bold
}
.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}
.mat-mdc-form-field-icon-prefix>.mat-icon, .mat-mdc-form-field-icon-suffix>.mat-icon {
  padding: 0 0 5px !important;
}
