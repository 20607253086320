@import '@angular/material/theming';

@mixin location-selector-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  location-selector {
    .location-button {
      border-color: mat.get-color-from-palette($accent);
    }

    .form-group .location-text {
      background-color: mat.get-color-from-palette($primary, darker);
      color: mat.get-color-from-palette($accent);
      border-color: mat.get-color-from-palette($accent);

      &::placeholder {
        color: mat.get-color-from-palette($accent, default-contrast);
      }
    }
  }
}
