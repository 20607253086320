/*
  Maps autocomplete styles momentarily here
*/
.pac-container {
  background: #90147f;
  border: 1px solid $color__primary;
  z-index: 10000;
}

.pac-item {
  border-top: 1px solid #c7c20b;
  color: $color__accent;
}

.pac-item-query {
  font-size: 13px;
  padding-right: 3px;
  color: #fff;
}

.pac-logo:after{
  display: none;
}

.pac-item:hover {
  background-color: #6f6100;
}
