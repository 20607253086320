@import '@angular/material/theming';

@mixin dashboard-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  .gg-page {
    background-color: darken(mat.get-color-from-palette($primary, 900), 7);
  }

  /*
  gingr-dashboard .sidenav--account {
    background-color: darken(mat.get-color-from-palette($primary), 10);
  }
  */
}
